import React, { useState } from 'react';
import './style.scss';
import { InputState } from '../general/input';
import { fetcher } from '../../utils/fetcher';
import { WelcomeScreen } from './welcomeScreen';
import { SignupScreen } from './signupScreen';
import { LoginScreen } from './loginScreen';

type Stage = 'welcome' | 'emailLogin' | 'emailSignup';

export const LoggedOut = () => {
  const [stage, setStage] = useState<Stage>('welcome');
  const [email, setEmail] = useState<InputState>({
    value: '',
    valid: true,
    hint: '',
  });

  const checkEmail = (email: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      fetcher.auth
        .checkEmail(email)
        .then((response) => {
          setStage(response.exists ? 'emailLogin' : 'emailSignup');
          resolve();
        })
        .catch((err) => reject(err));
    });
  };

  return (
    <div className='logged-out-wrapper'>
      <div className='content'>
        {(() => {
          switch (stage) {
            case 'welcome':
              return (
                <WelcomeScreen
                  email={email}
                  setEmail={setEmail}
                  moveToEmailAuth={checkEmail}
                />
              );
            case 'emailLogin':
              return (
                <LoginScreen
                  email={email.value}
                  goBack={() => setStage('welcome')}
                />
              );
            case 'emailSignup':
              return (
                <SignupScreen
                  email={email.value}
                  goBack={() => setStage('welcome')}
                />
              );
          }
        })()}
      </div>
    </div>
  );
};
