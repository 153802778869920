import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetcher } from '../utils/fetcher';
import localStorage from '../utils/localStorage';

interface IAuth {
  appLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  logout: () => void;
  loggedIn: boolean;
  authWithGoogle: (token: string, name: string | null) => void;
  prefferedName: string | null;
}

// @ts-ignore
const AuthContext = createContext<IAuth>();

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [prefferedName, setPreferredName] = useState<string | null>(null);

  useEffect(() => {
    const existing = localStorage.getToken();
    if (!existing) {
      setLoading(false);
    } else {
      validateToken(existing).then(() => setLoading(false));
    }
  }, []);

  const authorize = (
    email: string,
    password: string,
    method: typeof fetcher.auth.emailLogin
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      method(email, password)
        .then((response) => {
          localStorage.clear();
          localStorage.setToken(response.token);
          fetcher.setTokenHeader(response.token);
          setLoggedIn(true);
          resolve();
        })
        .catch((err) => {
          fetcher.user.clearHeaders();
          reject(err);
        });
    });
  };

  const authWithGoogle = (token: string, name: string | null) => {
    localStorage.clear();
    localStorage.setToken(token);
    localStorage.setAuthByGoogle();
    fetcher.setTokenHeader(token);
    //setLoggedIn(true);
    setPreferredName(name);
  };

  const login = (email: string, password: string) =>
    authorize(email, password, fetcher.auth.emailLogin);

  const signup = (email: string, password: string) =>
    authorize(email, password, fetcher.auth.emailSignup);

  const validateToken = (token: string): Promise<void> => {
    return new Promise((resolve) => {
      fetcher.auth
        .validateToken(token)
        .then(() => {
          fetcher.setTokenHeader(token);
          setLoggedIn(true);
          resolve();
        })
        .catch(() => {
          localStorage.clear();
          fetcher.user.clearHeaders();
          resolve();
        });
    });
  };

  const logout = () => {
    fetcher.auth
      .logout()
      .then(() => fetcher.user.clearHeaders())
      .catch(() => {
        /*do nothing*/
      })
      .finally(() => {
        const byGoogle = localStorage.getAuthByGoogle();
        localStorage.clear();
        setLoggedIn(false);
        if (byGoogle) {
          window.location.reload();
        }
      });
  };

  return (
    <AuthContext.Provider
      value={{
        appLoading: loading,
        loggedIn,
        login,
        logout,
        signup,
        authWithGoogle,
        prefferedName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
