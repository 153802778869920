import React, { useState } from 'react';
import '../style.scss';
import useAlert from '../../../contexts/alert';
import { useAuth } from '../../../contexts/AuthContext';
import { Input, InputState } from '../../general/input';
import { AppLogo } from '../../general/loader';
import { Button } from '../../general/button';
import { Link } from '../../general/link';
import { shortenEmail } from '../../../utils/shortenEmail';

interface LoginProps {
  email: string;
  goBack: () => void;
}

export const LoginScreen = ({ email, goBack }: LoginProps) => {
  const alert = useAlert();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<InputState>({
    value: '',
    valid: true,
    hint: '',
  });

  const handlePasswordChange = (newValue: string) => {
    setPassword({ value: newValue, valid: true, hint: '' });
  };

  const handleLoginClick = () => {
    if (password.value.length === 0) {
      setPassword((prev) => ({
        ...prev,
        valid: false,
        hint: 'Please enter password',
      }));
      return;
    }
    setLoading(true);
    login(email, password.value)
      .catch((err) => alert.showError(err))
      .finally(() => setLoading(false));
  };

  return (
    <div className='logged-out-wrapper'>
      <div className='content'>
        <AppLogo size={60} className='logo' />
        <h4 className='email-as-label'>{shortenEmail(email)}</h4>
        <Input
          value={password.value}
          onChange={(newValue) => handlePasswordChange(newValue)}
          autoFocus
          className='auth-input'
          placeholder='Password'
          valid={password.valid}
          hint={password.hint}
          type='password'
          onEnterPress={handleLoginClick}
        />
        <Button
          variant='contained'
          loading={loading}
          className='button-email'
          onClick={handleLoginClick}
          disabled={!password.valid}
        >
          Login
        </Button>
        <Link onClick={goBack} className='go-back-link'>
          Go Back
        </Link>
      </div>
    </div>
  );
};
