import React from 'react';
import './style.scss';
import { Button } from '../../../../general/button';
import { useUserData } from '../../../../../contexts/UserDataContext';

interface Props {
  onSuggestionClick: (suggestion: string) => any;
}

const suggestions = [
  'Generate a proposal and quotation',
  'Get ideation of a web3 strategy for my business',
];

export const ChatWelcome = ({ onSuggestionClick }: Props) => {
  const { name } = useUserData();

  return (
    <div className='chat-welcome'>
      <h4 className='title'>Welcome, {name}! How can I assist you today?</h4>
      <div className='buttons-wrapper'>
        {suggestions.map((s, i) => (
          <Button
            className='welcome-btn'
            variant='outlined'
            key={i}
            onClick={() => onSuggestionClick(s)}
          >
            {s}
          </Button>
        ))}
      </div>
    </div>
  );
};
