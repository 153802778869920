import React, { useState } from 'react';
import './style.scss';
import { Input, useInputState } from '../../general/input';
import { Button } from '../../general/button';
import { fetcher } from '../../../utils/fetcher';
import useAlert from '../../../contexts/alert';
import { useAuth } from '../../../contexts/AuthContext';

interface Props {
  reloadStage: () => void;
}

export const UserDataForm = ({ reloadStage }: Props) => {
  const alert = useAlert();
  const { prefferedName } = useAuth();
  const [name, setName, handleNameChange] = useInputState({
    valid: true,
    hint: '',
    value: prefferedName || '',
  });
  const [role, setRole, handleRoleChange] = useInputState();
  const [businessType, setBusinessType, handleBusinessTypeChange] =
    useInputState();
  const [loading, setLoading] = useState(false);

  const handleContinueClick = () => {
    let valid = true;
    if (!name.value) {
      valid = false;
      setName((prev) => ({
        ...prev,
        valid: false,
        hint: 'Please enter your name',
      }));
    }
    if (!role.value) {
      valid = false;
      setRole((prev) => ({
        ...prev,
        valid: false,
        hint: 'Please enter your role',
      }));
    }
    if (!businessType.value) {
      valid = false;
      setBusinessType((prev) => ({
        ...prev,
        valid: false,
        hint: 'Please enter your business type',
      }));
    }
    if (valid) {
      setLoading(true);
      fetcher.user
        .setData(name.value, role.value, businessType.value)
        .then(() => reloadStage())
        .catch((err) => alert.showError(err))
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className='user-data-form'>
      <div className='form'>
        <Input
          value={name.value}
          onChange={handleNameChange}
          label={'Name'}
          placeholder={'Enter your name'}
          className={'form-input'}
          valid={name.valid}
          hint={name.hint}
          autoFocus
        />
        <Input
          value={role.value}
          onChange={handleRoleChange}
          label={'Role'}
          placeholder={'Enter your role'}
          className={'form-input'}
          valid={role.valid}
          hint={role.hint}
        />
        <Input
          value={businessType.value}
          onChange={handleBusinessTypeChange}
          label={'Business Type'}
          placeholder={'Enter your business type'}
          className='form-input'
          valid={businessType.valid}
          hint={businessType.hint}
        />
        <Button
          variant='contained'
          className='form-button'
          disabled={!businessType.valid || !name.valid || !role.valid}
          onClick={handleContinueClick}
          loading={loading}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
