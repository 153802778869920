import React, { LegacyRef, useState } from 'react';
import './style.scss';
import clsx from 'clsx';
import { SetState } from '../../../utils/ISetState';

export interface InputState {
  value: string;
  hint: string | string[];
  valid: boolean;
}

export const DefaultInputState: InputState = {
  value: '',
  valid: true,
  hint: '',
};

export const useInputState = (
  initialValue: InputState = DefaultInputState
): [InputState, SetState<InputState>, (newValue: string) => void] => {
  const [state, setState] = useState<InputState>(initialValue);

  const handleChange = (newValue: string) => {
    setState({ valid: true, hint: '', value: newValue });
  };

  return [state, setState, handleChange];
};

interface Props {
  value: string;
  onChange: (newValue: string) => any;
  className?: string;
  placeholder?: string;
  onEnterPress?: () => any;
  autoFocus?: boolean;
  valid?: boolean;
  hint?: string | string[];
  type?: 'text' | 'password' | 'phone';
  disabled?: boolean;
  id?: string;
  label?: string;
  multi?: boolean;
  children?: React.ReactNode;
  textAreaRef?: LegacyRef<HTMLTextAreaElement>;
}

const notNumeric = new RegExp(/\D/);

export const Input = ({
  children,
  value,
  onChange,
  className = '',
  autoFocus = false,
  placeholder = undefined,
  onEnterPress,
  valid = true,
  hint: rawHint,
  type = 'text',
  disabled = false,
  id,
  label,
  multi,
  textAreaRef,
}: Props) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (type === 'phone' && !multi) {
      const toTest = newValue[0] === '+' ? newValue.substring(1) : newValue;
      if (notNumeric.test(toTest)) return;
    }
    onChange(newValue);
  };

  const onKeyDown = (e) => {
    if (onEnterPress && e.key === 'Enter') {
      onEnterPress();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const props = {
    id,
    value,
    onChange: handleChange,
    onKeyDown,
    className: 'input',
    autoFocus,
    placeholder,
    disabled,
  };

  const hint: string[] = rawHint
    ? typeof rawHint === 'string'
      ? [rawHint]
      : rawHint
    : [];

  return (
    <div
      className={clsx(
        'general--input-wrapper',
        !valid && 'input-wrapper-invalid',
        multi && 'input-wrapper-multi',
        className
      )}
    >
      {label && <div className='label'>{label}</div>}
      {multi ? (
        <>
          <textarea {...props} rows={1} ref={textAreaRef} />
          <pre className='multi-replicator'>
            <span>{value} </span>
          </pre>
        </>
      ) : (
        <input {...props} type={type} />
      )}
      {hint.length > 0 && <div className='hint'>{hint[0]}</div>}
      {children}
    </div>
  );
};
