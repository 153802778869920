type ValidationSuccess = {
  result: true;
};

type ValidationFailure = {
  result: false;
  reason: string;
};

type ValidationResult = ValidationSuccess | ValidationFailure;

export const isEmailValid = (email: string): ValidationResult => {
  // noinspection SuspiciousTypeOfGuard
  if (typeof email !== 'string') {
    return {
      result: false,
      reason: 'The email address is incorrect',
    };
  }
  if (!email || email.length === 0) {
    return {
      result: false,
      reason: 'Please enter the email address',
    };
  }
  const regex = new RegExp(
    '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])'
  );
  const result = regex.test(email);
  if (result) return { result };
  return {
    result: false,
    reason: 'The email address is incorrect',
  };
};

export const isPasswordValid = (password: string): ValidationResult => {
  if (typeof password !== 'string') {
    return {
      result: false,
      reason: 'Incorrect password format',
    };
  }
  if (!password || password.length === 0) {
    return {
      result: false,
      reason: 'Please enter password',
    };
  }
  if (password.length < 8) {
    return {
      result: false,
      reason: 'Password should be at least 8 characters',
    };
  }
  return { result: true };
};
