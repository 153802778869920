export enum ChatRoles {
  SYSTEM = 'system',
  USER = 'user',
  GPT = 'assistant',
}

export type ChatMessage = {
  role: ChatRoles;
  message: string;
};

export type APIChatCreate = {
  id: string;
  title: string;
  message: string;
};

export type APIChatGetResponse = {
  message: string;
};

export type APIChatGetMessages = {
  messages: ChatMessage[];
};

export type APIChat = { id: string; title: string };

export type APIChatGetChats = {
  chats: APIChat[];
};
