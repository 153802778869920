import { APIAuthCheckEmail, APIAuthEmailLogin } from '../../../shared/api/auth';
import {
  APIUserGetNextQuestion,
  APIUserGetStage,
  APIUserNoNextQuestion,
} from '../../../shared/api/user';
import {
  APIChatCreate,
  APIChatGetChats,
  APIChatGetMessages,
  APIChatGetResponse,
} from '../../../shared/api/chat';

type ResponseOK<T> = {
  success: true;
  data: T;
};

type ResponseErr<T> = {
  success: false;
  error: any;
};

type Response<T> = ResponseOK<T> | ResponseErr<T>;

type Sender = () => Promise<globalThis.Response>;

abstract class APIFetcher {
  private headers: { [key: string]: string } = {};

  private processRequest = <T>(sender: Sender): Promise<T> => {
    return new Promise<T>((resolve, reject) => {
      sender()
        .then((r) => {
          return new Promise<Response<T>>((resolve, reject) => {
            const status = r.status;
            r.text()
              .then((text) => {
                const json = text.length > 0 ? JSON.parse(text) : undefined;
                if (status !== 200 && status !== 304) {
                  resolve({ success: false, error: json });
                } else {
                  resolve({ success: true, data: json });
                }
              })
              .catch((err) => reject(err));
          });
        })
        .then((response: Response<T>) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((err) => reject(err));
    });
  };

  protected get = <T>(url: string, params?: any): Promise<T> => {
    const finalUrl = params
      ? `${url}?${new URLSearchParams(params).toString()}`
      : url;
    const sender: Sender = () => {
      return fetch(finalUrl, {
        method: 'GET',
        headers: this.headers,
      });
    };
    return this.processRequest<T>(sender);
  };

  protected post = <T>(url: string, payload?: any): Promise<T> => {
    const sender: Sender = () => {
      return fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': ' application/json',
          ...this.headers,
        },
        body: JSON.stringify(payload),
      });
    };
    return this.processRequest<T>(sender);
  };

  public setTokenHeader = (token: string) => {
    this.headers = { 'x-auth': token };
  };

  public clearHeaders = () => {
    this.headers = {};
  };
}

class AuthFetcher extends APIFetcher {
  public emailSignup = (email: string, password: string) => {
    return this.post<APIAuthEmailLogin>('/api/auth/emailsignup', {
      email,
      password,
    });
  };

  public emailLogin = (email: string, password: string) => {
    return this.post<APIAuthEmailLogin>('/api/auth/emaillogin', {
      email,
      password,
    });
  };

  public checkEmail = (email: string) => {
    return this.post<APIAuthCheckEmail>('/api/auth/checkemail', { email });
  };

  public validateToken = (token: string) => {
    return this.post('/api/auth/validatetoken', {
      token,
    });
  };

  public logout = () => {
    return this.get('/api/auth/logout');
  };
}

class UserFetcher extends APIFetcher {
  public getStage = () => {
    return this.get<APIUserGetStage>('/api/user/getstage');
  };

  public setData = (name: string, role: string, businessType: string) => {
    return this.post('/api/user/setdata', { name, role, businessType });
  };

  public getNextQuestion = () => {
    return this.get<APIUserGetNextQuestion | APIUserNoNextQuestion>(
      '/api/user/nextquestion'
    );
  };

  public answerQuestion = (question: string, answer: string) => {
    return this.post<APIUserGetNextQuestion | APIUserNoNextQuestion>(
      '/api/user/answerquestion',
      { question, answer }
    );
  };
}

class ChatFetcher extends APIFetcher {
  public startNewChat = (message: string) => {
    return this.post<APIChatCreate>('/api/chat/new', { message });
  };

  public getChats = () => {
    return this.post<APIChatGetChats>('/api/chat/getchats');
  };

  public getResponse = (chatId: string, message: string) => {
    return this.post<APIChatGetResponse>('/api/chat/getresponse', {
      chatId,
      message,
    });
  };

  public loadChatMessages = (chatId: string) => {
    return this.post<APIChatGetMessages>('/api/chat/getmessages', { chatId });
  };
}

class Fetcher {
  private readonly _auth: AuthFetcher;
  private readonly _user: UserFetcher;
  private readonly _chat: ChatFetcher;

  constructor() {
    this._user = new UserFetcher();
    this._auth = new AuthFetcher();
    this._chat = new ChatFetcher();
  }

  public setTokenHeader = (token: string) => {
    this._user.setTokenHeader(token);
    this._auth.setTokenHeader(token);
    this._chat.setTokenHeader(token);
  };

  get auth(): AuthFetcher {
    return this._auth;
  }

  get user(): UserFetcher {
    return this._user;
  }

  get chat(): ChatFetcher {
    return this._chat;
  }
}

export const fetcher = new Fetcher();
