export enum UserStage {
  INIT = 'init',
  INTERVIEW = 'interview',
  COMPLETE = 'complete',
}

export interface APIUserGetStage {
  stage: UserStage;
  name: string;
}

export type APIUserGetNextQuestion = {
  question: string;
  complete: number;
  total: number;
};

export type APIUserNoNextQuestion = {
  done: true;
};
