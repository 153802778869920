import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import useAlert from '../../../contexts/alert';
import { useStateWithFetch } from '../../../utils/useStateWithFetch';
import { APIUserGetNextQuestion } from '../../../../../shared/api/user';
import { Loader } from '../../general/loader';
import { fetcher } from '../../../utils/fetcher';
import { GptTextArea } from '../../general/gptTextArea';

interface Props {
  reloadStage: () => void;
}

export const UserInterview = ({ reloadStage }: Props) => {
  const alert = useAlert();
  const interviewData = useStateWithFetch<APIUserGetNextQuestion>();
  const [answer, setAnswer] = useState('');
  const [newLoading, setNewLoading] = useState(false);
  const answerRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    updateQuestion();
  }, []);

  const handleSend = () => {
    if (!answer || interviewData.stage !== 'loaded') return;
    updateQuestion(interviewData.value.question, answer);
  };

  const updateQuestion = (question?: string, answer?: string) => {
    setNewLoading(true);
    const method =
      question && answer
        ? () => fetcher.user.answerQuestion(question, answer)
        : () => fetcher.user.getNextQuestion();
    method()
      .then((response) => {
        if ('done' in response) {
          reloadStage();
        } else {
          setAnswer('');
          setTimeout(() => {
            if (answerRef.current) {
              answerRef.current.focus();
            }
          }, 100);
          interviewData.setValue(response);
        }
      })
      .catch((err) => {
        interviewData.setError(err?.message);
        alert.showError(err);
      })
      .finally(() => setNewLoading(false));
  };

  if (interviewData.stage === 'error') {
    return null;
  }

  if (interviewData.stage === 'loading') {
    return (
      <div className='user-interview'>
        <div className='loader-wrapper'>
          <Loader size={120} />
        </div>
      </div>
    );
  }

  return (
    <div className='user-interview'>
      <div className='counter'>
        {!newLoading &&
          `${interviewData.value.complete + 1}/${interviewData.value.total}`}
      </div>
      <div className='interview-question'>
        {newLoading ? <Loader size={40} /> : interviewData.value.question}
      </div>
      <GptTextArea
        value={answer}
        setValue={setAnswer}
        disabled={newLoading}
        onSubmit={handleSend}
        placeholder={'Type answer here'}
        inputRef={answerRef}
      />
    </div>
  );
};
