import React from 'react';
import './style.scss';
import { Button } from '../button';
import localStorage from '../../../utils/localStorage';

interface Props {
  children: string;
}

export const ErrorComponent = ({ children }: Props) => {
  const refresh = () => {
    window.location.reload();
  };

  const reset = () => {
    localStorage.clear();
    refresh();
  };

  return (
    <div className='general-error'>
      <h4 className='error-title'>{children}</h4>
      <Button className='error-button' variant='contained' onClick={refresh}>
        Refresh the Page
      </Button>
      <Button className='error-button' variant='outlined' onClick={reset}>
        Restart application
      </Button>
    </div>
  );
};
