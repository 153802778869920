import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { ChatMessages } from './messages';
import { GptTextArea } from '../../../general/gptTextArea';
import { ChatMessage, ChatRoles } from '../../../../../../shared/api/chat';
import useAlert from '../../../../contexts/alert';
import { fetcher } from '../../../../utils/fetcher';
import { Loader } from '../../../general/loader';
import { ChatWelcome } from './welcome';

interface Props {
  chatId: string | null;
  startNewChat: (msg: string) => Promise<string>;
  loading: boolean;
}

export const ChatArea = ({ chatId, startNewChat, loading }: Props) => {
  const alert = useAlert();
  const [messageInput, setMessageInput] = useState('');
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [chatLoading, setChatLoading] = useState(false);
  const messagesAreaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatId) {
      loadMessages(chatId);
    } else {
      setMessages([]);
    }
    setMessageInput('');
  }, [chatId]);

  useEffect(() => {
    if (messagesAreaRef.current) {
      messagesAreaRef.current.scrollTo({
        top: messagesAreaRef.current.scrollHeight,
      });
    }
  }, [messages]);

  const loadMessages = (id: string) => {
    setChatLoading(true);
    fetcher.chat
      .loadChatMessages(id)
      .then((result) => {
        // for a case user switched chats while messages were loading
        if (id === chatId) {
          setMessages(result.messages);
        }
      })
      .catch((err) => alert.showError(err))
      .finally(() => setChatLoading(false));
  };

  const addMessage = (message: ChatMessage) => {
    setMessages((prev) => prev.concat(message));
  };

  const sendMessage = (msg: string) => {
    if (!msg) return;
    const id = chatId;
    setChatLoading(true);
    addMessage({ role: ChatRoles.USER, message: msg });
    setMessageInput('');

    if (id) {
      sendToExisting(msg, id);
    } else {
      startNew(msg);
    }
  };

  const sendToExisting = (msg: string, id: string) => {
    fetcher.chat
      .getResponse(id, msg)
      .then((response) =>
        addMessage({
          role: ChatRoles.GPT,
          message: response.message,
        })
      )
      .catch(() => {
        if (id === chatId) {
          setMessageInput(msg);
          setMessages((prev) => prev.slice(0, prev.length - 1));
        }
      })
      .finally(() => setChatLoading(false));
  };

  const startNew = (msg: string) => {
    startNewChat(msg)
      .then((answer) => {
        addMessage({ role: ChatRoles.GPT, message: answer });
      })
      .catch(() => {
        setMessageInput(msg);
        setMessages((prev) => prev.slice(0, prev.length - 1));
      })
      .finally(() => setChatLoading(false));
  };

  const handleSuggestionClick = (suggestion: string) => {
    sendMessage(suggestion);
  };

  if (loading) {
    return (
      <div className='chat-area with-loader'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='chat-area'>
      {messages.length > 0 ? (
        <ChatMessages
          messages={messages}
          loading={chatLoading}
          messagesAreaRef={messagesAreaRef}
        />
      ) : (
        <ChatWelcome onSuggestionClick={handleSuggestionClick} />
      )}
      <GptTextArea
        value={messageInput}
        setValue={setMessageInput}
        disabled={chatLoading}
        onSubmit={() => sendMessage(messageInput)}
        placeholder={'Type your message here'}
      />
    </div>
  );
};
