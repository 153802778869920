import { useState } from 'react';

type Loading = {
  stage: 'loading';
};

type Error = {
  stage: 'error';
  message?: string;
};

type Loaded<T> = {
  stage: 'loaded';
  value: T;
};

export type StateWithFetch<T> = Loading | Error | Loaded<T>;

type UseStateWithFetch<T> = StateWithFetch<T> & {
  setLoading: () => void;
  setError: (message?: string) => void;
  setValue: (value: T) => void;
  setValueFromPrev: (setter: (prev: T) => any) => void;
};

export const useStateWithFetch = <T>(): UseStateWithFetch<T> => {
  const [state, setState] = useState<StateWithFetch<T>>({ stage: 'loading' });

  const setLoading = () => {
    setState({ stage: 'loading' });
  };

  const setError = (message?: string) => {
    setState({ stage: 'error', message });
  };

  const setValue = (value: T) => {
    setState({ stage: 'loaded', value });
  };

  const setValueFromPrev = (setter: (prev: T) => any) => {
    if (state.stage !== 'loaded') return;
    const prevValue = state.value;
    setState({ stage: 'loaded', value: setter(prevValue) });
  };

  return {
    ...state,
    setLoading,
    setError,
    setValue,
    setValueFromPrev,
  };
};
