// noinspection HtmlUnknownAttribute

import React, { useState } from 'react';
import '../style.scss';
import { Input, InputState } from '../../general/input';
import useAlert from '../../../contexts/alert';
import { SetState } from '../../../utils/ISetState';
import { isEmailValid } from '../../../../../shared/validators';
import { AppLogo } from '../../general/loader';
import { Button } from '../../general/button';
import { GoogleIcon } from './googleIcon';

interface WelcomeScreenProps {
  email: InputState;
  setEmail: SetState<InputState>;
  moveToEmailAuth: (email: string) => Promise<void>;
}

export const WelcomeScreen = ({
  email,
  setEmail,
  moveToEmailAuth,
}: WelcomeScreenProps) => {
  const alert = useAlert();
  const [emailLoading, setEmailLoading] = useState(false);

  const handleEmailClick = () => {
    const validation = isEmailValid(email.value);
    if (!validation.result) {
      setEmail((prev) => ({ ...prev, valid: false, hint: validation.reason }));
      return;
    }
    setEmailLoading(true);
    moveToEmailAuth(email.value).catch((err) => {
      alert.showError(err);
      setEmailLoading(false);
    });
  };

  const handleEmailChange = (newValue: string) => {
    setEmail({ value: newValue, valid: true, hint: '' });
  };

  const handleGoogleClick = () => {
    const divWrapper = document.getElementById('actual_google_login_button');
    if (divWrapper) {
      const actualButton = divWrapper.querySelector('div[role="button"]');
      if (actualButton) {
        console.log('Clicking');
        // @ts-ignore
        actualButton.click();
      }
    }
  };

  // TODO:: replace clientID with variable from config

  return (
    <div className='logged-out-wrapper'>
      <div className='content'>
        <AppLogo size={60} className='logo' />
        <Input
          value={email.value}
          onChange={(newValue) => handleEmailChange(newValue)}
          autoFocus
          className='auth-input'
          placeholder='Email'
          valid={email.valid}
          hint={email.hint}
          onEnterPress={handleEmailClick}
        />
        <Button
          variant='contained'
          loading={emailLoading}
          className='button-email'
          onClick={handleEmailClick}
        >
          Continue With Email
        </Button>
        <div className='or-divider'>
          <div className='line' />
          <div className='or'>OR</div>
          <div className='line' />
        </div>
        <div
          id='g_id_onload'
          data-client_id='813044908000-59811fpilda6iq1m8k1q8220t0c49ug9.apps.googleusercontent.com'
          data-context='signin'
          data-ux_mode='redirect'
          data-login_uri='/api/auth/googleauth'
          data-nonce=''
          data-auto_prompt='false'
        />
        <div
          id='actual_google_login_button'
          className='g_id_signin'
          data-type='standard'
          data-shape='rectangular'
          data-theme='outline'
          data-text='continue_with'
          data-size='large'
        />
        <Button
          variant='outlined'
          className='button-google'
          onClick={handleGoogleClick}
        >
          <GoogleIcon className='google-icon' />
          <p className='google-text'>Continue With Google</p>
        </Button>
      </div>
    </div>
  );
};
