import React from 'react';
import { useAuth } from './contexts/AuthContext';
import { AppLoader } from './components/app/loader';
import { LoggedOut } from './components/loggedOut';
import { LoggedIn } from './components/loggedIn';
import { Route, Routes } from 'react-router-dom';
import { GoogleLoginHandler } from './components/app/googleLoginHandler';

export const App = () => {
  const { loggedIn, appLoading } = useAuth();

  return (
    <>
      <AppLoader visible={appLoading} />
      {loggedIn ? (
        <LoggedIn />
      ) : (
        <Routes>
          <Route path='/' element={<LoggedOut />} />
          <Route path='/googlelogin/:result' element={<GoogleLoginHandler />} />
        </Routes>
      )}
    </>
  );
};
