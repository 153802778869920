import { isEmailValid } from '../../../shared/validators';

export const shortenEmail = (email: string) => {
  if (!isEmailValid(email).result) return '';
  if (email.length > 20) {
    const splitted = email.split('@');
    if (splitted.length !== 2 || splitted[0].length < 10) {
      return `${email.substring(0, 5)}...${email.substring(email.length - 5)}`;
    }
    const [a, b] = splitted;
    return `${a.substring(0, 5)}...${a[a.length - 1]}@${b}`;
  }
  return email;
};
