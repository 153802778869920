import React from 'react';
import './index.scss';
import { createRoot } from 'react-dom/client';
import { AlertContextProvider } from './contexts/alert';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { AuthContextProvider } from './contexts/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AlertContextProvider>
    <GoogleOAuthProvider clientId='813044908000-59811fpilda6iq1m8k1q8220t0c49ug9.apps.googleusercontent.com'>
      <AuthContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthContextProvider>
    </GoogleOAuthProvider>
  </AlertContextProvider>
);
