import React from 'react';
import './style.scss';
import { AppLogo } from '../../general/loader';
import { useAuth } from '../../../contexts/AuthContext';
import { useUserData } from '../../../contexts/UserDataContext';

export const LoggedInHeader = () => {
  const { logout } = useAuth();
  const { name } = useUserData();

  return (
    <div className='logged-in-header'>
      <AppLogo size={40} className='app-logo' />
      <div className='control-part'>
        <div className='username'>{name}</div>
        <SVGLogout
          color={'#FFF'}
          onClick={logout}
          size={25}
          className='logout-icon'
        />
      </div>
    </div>
  );
};

interface IconProps {
  color: string;
  onClick: () => void;
  size: number;
  className: string;
}

const SVGLogout = ({
  color = '#FFF',
  className,
  onClick,
  size = 29,
}: IconProps) => (
  <svg
    width={size}
    height={size * 0.66}
    viewBox='0 0 29 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    onClick={onClick}
  >
    <path
      d='M24.0054 0H14.2555C12.931 0.0016467 11.6612 0.535572 10.7248 1.48462C9.78845 2.43367 9.26195 3.7203 9.26086 5.06218V6.24377H12.1255V5.06218C12.126 4.48948 12.3508 3.94039 12.7505 3.53542C13.1503 3.13045 13.6923 2.9027 14.2576 2.90216H24.0074C24.5725 2.9027 25.1143 3.13052 25.5137 3.53555C25.9131 3.94057 26.1375 4.48967 26.1375 5.06218V14.9399C26.1369 15.512 25.9123 16.0606 25.513 16.4652C25.1136 16.8698 24.5722 17.0973 24.0074 17.0978H14.2576C13.6924 17.0978 13.1504 16.8705 12.7506 16.4659C12.3509 16.0613 12.126 15.5124 12.1255 14.9399V13.7562H9.26086V14.9399C9.26249 16.2818 9.78952 17.5682 10.7263 18.5168C11.6631 19.4655 12.933 19.9989 14.2576 20H24.0074C25.3316 19.9984 26.6011 19.4647 27.5374 18.5161C28.4737 17.5675 29.0004 16.2814 29.0021 14.9399V5.06218C29.001 3.71995 28.4742 2.433 27.5374 1.48389C26.6006 0.534785 25.3303 0.00109795 24.0054 0Z'
      fill={color}
    />
    <path
      d='M5.52258 16.165L7.41936 14.2433L4.57316 11.3598H18.4685V8.64217H4.56704L7.41936 5.75868L5.52258 3.83704L0.407214 9.01946C0.280757 9.14731 0.180434 9.29917 0.111982 9.46635C0.0435294 9.63353 0.00830078 9.81276 0.00830078 9.99375C0.00830078 10.1747 0.0435294 10.354 0.111982 10.5211C0.180434 10.6883 0.280757 10.8402 0.407214 10.9681L5.52258 16.165Z'
      fill={color}
    />
  </svg>
);

export default SVGLogout;
