import React from 'react';
import './style.scss';
import { APIChat } from '../../../../../../shared/api/chat';
import { Button } from '../../../general/button';
import clsx from 'clsx';
import { Loader } from '../../../general/loader';
import { StateWithFetch } from '../../../../utils/useStateWithFetch';

interface Props {
  chats: StateWithFetch<APIChat[]>;
  onSelect: (id: string | null) => void;
  activeId: string | null;
}

export const ChatLeftPanel = ({ chats, onSelect, activeId }: Props) => {
  if (chats.stage === 'loading') {
    return (
      <div className='chat-left-panel'>
        <div className='loader-wrapper'>
          <Loader size={30} />
        </div>
      </div>
    );
  }

  if (chats.stage === 'error') {
    return (
      <div className='chat-left-panel'>
        <div className='error'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='#EB4F45'
            className='error-icon'
            viewBox='0 0 16 16'
          >
            <path d='M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z' />
          </svg>
          <div className='error-text'>
            {chats.message || "Can't load chats"}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='chat-left-panel'>
      <div className='new-chat-wrapper'>
        <Button
          variant='outlined'
          className='new-chat-button'
          onClick={() => onSelect(null)}
        >
          New Chat
        </Button>
      </div>
      <div className='chats'>
        {chats.value.map((chat, index) => (
          <button
            key={index}
            className={clsx('chat-button', activeId === chat.id && 'active')}
            onClick={() => onSelect(chat.id)}
          >
            <div className='chat-button-title'>{chat.title}</div>
          </button>
        ))}
      </div>
    </div>
  );
};
