import React, { LegacyRef } from 'react';
import './style.scss';
import { Input } from '../input';
import { SetState } from '../../../utils/ISetState';
import clsx from 'clsx';

interface Props {
  value: string;
  setValue: SetState<string>;
  disabled: boolean;
  onSubmit: () => void;
  className?: string;
  placeholder?: string;
  inputRef?: LegacyRef<HTMLTextAreaElement>;
}

export const GptTextArea = ({
  value,
  setValue,
  disabled,
  onSubmit,
  className,
  placeholder,
  inputRef,
}: Props) => (
  <Input
    multi
    value={value}
    onChange={(newValue) => setValue(newValue)}
    className={clsx('gpt-text-area', className)}
    placeholder={placeholder}
    onEnterPress={onSubmit}
    disabled={disabled}
    autoFocus
    textAreaRef={inputRef}
  >
    <EnterButton onClick={onSubmit} disabled={disabled} />
  </Input>
);

interface EnterButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const EnterButton = ({ onClick, disabled }: EnterButtonProps) => (
  <button
    className={clsx('enter-button', disabled && 'disabled')}
    onClick={disabled ? undefined : onClick}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill={'#FFF'}
      viewBox='0 0 16 16'
    >
      <path
        fillRule='evenodd'
        d='M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z'
      />
    </svg>
  </button>
);
