import React from 'react';
import './style.scss';
import clsx from 'clsx';

interface Props {
  children: React.ReactNode | string;
  className?: string;
  onClick?: () => any;
}

export const Link = ({ children, className, onClick }: Props) => (
  <button className={clsx('general--link', className)} onClick={onClick}>
    {children}
  </button>
);
