import React from 'react';
import './style.scss';
import { Loader } from '../../general/loader';
import clsx from 'clsx';

interface Props {
  visible: boolean;
}

export const AppLoader = ({ visible }: Props) => (
  <div className={clsx('app-loader', visible && 'visible')}>
    <Loader size={80} />
  </div>
);
