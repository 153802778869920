import React, { LegacyRef, useMemo } from 'react';
import './style.scss';
import { ChatMessage, ChatRoles } from '../../../../../../../shared/api/chat';
import { AppLogo } from '../../../../general/loader';
import clsx from 'clsx';
import { useUserData } from '../../../../../contexts/UserDataContext';

interface Props {
  messages: ChatMessage[];
  loading: boolean;
  messagesAreaRef: LegacyRef<HTMLDivElement>;
}

export const ChatMessages = ({ messages, loading, messagesAreaRef }: Props) => (
  <div className='chat-messages-wrapper' ref={messagesAreaRef}>
    <div className='chat-messages'>
      {messages.map((msg, index) => (
        <Message data={msg} key={index} />
      ))}
      <div className={clsx('loader-wrapper', loading && 'visible')}>
        <Message data={{ role: ChatRoles.GPT, message: '' }} loaderMode />
      </div>
    </div>
  </div>
);

interface MessageProps {
  data: ChatMessage;
  loaderMode?: boolean;
}

const Message = ({ data, loaderMode }: MessageProps) => {
  const Component = data.role === ChatRoles.USER ? Avatar : AppLogo;

  return (
    <div
      className={clsx(
        'msg-wrapper',
        data.role === ChatRoles.USER && 'msg-wrapper-right'
      )}
    >
      <Component className='msg-avatar left' />
      <pre className={clsx('msg-body', loaderMode && 'loader-mode')}>
        {data.message}
      </pre>
      <Component className='msg-avatar right' />
    </div>
  );
};

interface AvatarProps {
  className?: string;
}

const Avatar = ({ className }: AvatarProps) => {
  const { name } = useUserData();

  const transformedName = useMemo(() => {
    if (!name) return '';
    const splitted = name.split(' ');
    if (splitted.length > 1) {
      return splitted[0][0] + splitted[1][0];
    }
    if (name.length > 1) {
      return name.substring(0, 2);
    }
    return name;
  }, [name]);

  return (
    <div className={clsx('msg-avatar', 'user-avatar', className)}>
      {transformedName}
    </div>
  );
};
