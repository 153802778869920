import React, { useEffect, useState } from 'react';
import './style.scss';
import useAlert, { ejectErrorMessage } from '../../../contexts/alert';
import { fetcher } from '../../../utils/fetcher';
import { ChatLeftPanel } from './leftPanel';
import { ChatArea } from './chatArea';
import { APIChat } from '../../../../../shared/api/chat';
import { useStateWithFetch } from '../../../utils/useStateWithFetch';

export const Chat = () => {
  const alert = useAlert();
  const chats = useStateWithFetch<APIChat[]>();
  const [activeChatId, setActiveChatId] = useState<string | null>(null);

  useEffect(() => {
    getChats();
  }, []);

  const getChats = () => {
    fetcher.chat
      .getChats()
      .then((result) => chats.setValue(result.chats))
      .catch((err) => {
        alert.showError(err);
        chats.setError(ejectErrorMessage(err));
      });
  };

  const handleChatSelect = (id: string | null) => {
    setActiveChatId(id);
  };

  const startNewChat = (message: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      fetcher.chat
        .startNewChat(message)
        .then((result) => {
          if (chats.stage !== 'loaded') {
            chats.setValue([result]);
          } else {
            chats.setValueFromPrev((prev) =>
              [{ id: result.id, title: result.title }].concat(prev)
            );
          }
          setActiveChatId(result.id);
          resolve(result.message);
        })
        .catch((err) => {
          alert.showError(err);
          reject();
        });
    });
  };

  return (
    <div className='main-chat-wrapper'>
      <ChatLeftPanel
        chats={chats}
        onSelect={handleChatSelect}
        activeId={activeChatId}
      />
      <ChatArea
        chatId={activeChatId}
        startNewChat={startNewChat}
        loading={chats.stage === 'loading'}
      />
    </div>
  );
};
