import React, { useEffect } from 'react';
import './style.scss';
import { APIUserGetStage, UserStage } from '../../../../shared/api/user';
import { fetcher } from '../../utils/fetcher';
import useAlert from '../../contexts/alert';
import { useStateWithFetch } from '../../utils/useStateWithFetch';
import { UserDataForm } from './userDataForm';
import { LoggedInHeader } from './header';
import { UserInterview } from './interview';
import { Chat } from './chat';
import { UserDataProvider } from '../../contexts/UserDataContext';
import { AppLoader } from '../app/loader';
import { ErrorComponent } from '../general/error';

export const LoggedIn = () => {
  const alert = useAlert();
  const data = useStateWithFetch<APIUserGetStage>();

  useEffect(() => {
    getStage();
  }, []);

  const getStage = () => {
    fetcher.user
      .getStage()
      .then((response) => {
        data.setValue(response);
      })
      .catch((err) => {
        alert.showError(err);
        data.setError(err?.message);
      });
  };

  if (data.stage === 'loading') {
    return <AppLoader visible={true} />;
  }

  if (data.stage === 'error') {
    return <ErrorComponent>{data.message || 'Error'}</ErrorComponent>;
  }

  return (
    <UserDataProvider name={data.value.name}>
      <div className='logged-in-wrapper'>
        <LoggedInHeader />
        <div className='content'>
          <LoggedInContent data={data.value} reloadStage={getStage} />
        </div>
      </div>
    </UserDataProvider>
  );
};

interface ContentProps {
  data: APIUserGetStage;
  reloadStage: () => void;
}

const LoggedInContent = ({ data, reloadStage }: ContentProps) => {
  switch (data.stage) {
    case UserStage.INIT:
      return <UserDataForm reloadStage={reloadStage} />;
    case UserStage.INTERVIEW:
      return <UserInterview reloadStage={reloadStage} />;
    case UserStage.COMPLETE:
      return <Chat />;
  }
};
