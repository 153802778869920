import React, { useState } from 'react';
import '../style.scss';
import useAlert from '../../../contexts/alert';
import { useAuth } from '../../../contexts/AuthContext';
import { Input, InputState } from '../../general/input';
import { isPasswordValid } from '../../../../../shared/validators';
import { AppLogo } from '../../general/loader';
import { Button } from '../../general/button';
import { Link } from '../../general/link';
import { shortenEmail } from '../../../utils/shortenEmail';

interface SignupProps {
  email: string;
  goBack: () => void;
}

export const SignupScreen = ({ email, goBack }: SignupProps) => {
  const alert = useAlert();
  const { signup } = useAuth();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<InputState>({
    value: '',
    valid: true,
    hint: '',
  });
  const [passwordC, setPasswordC] = useState<InputState>({
    value: '',
    valid: true,
    hint: '',
  });

  const handlePasswordChange = (newValue: string) => {
    setPassword({ value: newValue, valid: true, hint: '' });
    setPasswordC((prev) => ({ ...prev, valid: true, hint: '' }));
  };

  const handlePasswordCChange = (newValue: string) => {
    setPasswordC({ value: newValue, valid: true, hint: '' });
  };

  const handleSignupClick = () => {
    let result = true;
    const passwordValidation = isPasswordValid(password.value);
    if (!passwordValidation.result) {
      setPassword((prev) => ({
        ...prev,
        valid: false,
        hint: passwordValidation.reason,
      }));
      result = false;
    }
    if (passwordC.value !== password.value) {
      setPasswordC((prev) => ({
        ...prev,
        valid: false,
        hint: "Passwords don't match",
      }));
      result = false;
    }
    if (!result) return;
    setLoading(true);
    signup(email, password.value)
      .catch((err) => alert.showError(err))
      .finally(() => setLoading(false));
  };

  return (
    <div className='logged-out-wrapper'>
      <div className='content'>
        <AppLogo size={60} className='logo' />
        <h4 className='email-as-label'>{shortenEmail(email)}</h4>
        <Input
          value={password.value}
          onChange={(newValue) => handlePasswordChange(newValue)}
          autoFocus
          className='auth-input'
          placeholder='Password'
          valid={password.valid}
          hint={password.hint}
          type='password'
        />
        <Input
          value={passwordC.value}
          onChange={(newValue) => handlePasswordCChange(newValue)}
          className='auth-input'
          placeholder='Password Confirmation'
          valid={passwordC.valid}
          hint={passwordC.hint}
          type='password'
        />
        <Button
          variant='contained'
          loading={loading}
          className='button-email'
          onClick={handleSignupClick}
          disabled={!password.valid || !passwordC.valid}
        >
          Create Account
        </Button>
        <Link onClick={goBack} className='go-back-link'>
          Go Back
        </Link>
      </div>
    </div>
  );
};
