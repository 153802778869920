const configName = 'AI2MR';

type Config = {
  [key: string]: string;
};

const getConfig = (): Config => {
  const raw: string = localStorage.getItem(configName) || '{}';
  return JSON.parse(raw) || {};
};

const setConfig = (newC: Config) => {
  localStorage.setItem(configName, JSON.stringify(newC));
};

const setItem = (key: string, value: string) => {
  const config = getConfig();
  config[key] = value;
  setConfig(config);
};

const getItem = (key: string): string | undefined => {
  const config = getConfig();
  return config[key];
};

const setToken = (token) => setItem('token', token);
const getToken = () => getItem('token');

const setAuthByGoogle = () => setItem('auth_by_google', 'true');
const getAuthByGoogle = () => !!getItem('auth_by_google');

const clear = () => {
  localStorage.setItem(configName, JSON.stringify({}));
};

export default {
  setItem,
  getItem,
  setToken,
  getToken,
  setAuthByGoogle,
  getAuthByGoogle,
  clear,
};
