import React from 'react';
import './style.scss';
import clsx from 'clsx';

interface Props {
  size?: number;
  color?: string;
  className?: string;
}

export const Loader = ({ size = 80, color = '#FFF', className }: Props) => {
  return (
    <div
      className={clsx('general--loader-wrapper', className)}
      style={{ width: size, height: size * 1.152 }}
    >
      <AppLogo className='loader' color={color} />
    </div>
  );
};

export const AppLogo = ({ color = '#FFF', size = 46, className }: Props) => (
  <svg
    width={size}
    height={size * 1.152}
    viewBox='0 0 46 53'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M38.0953 8.5563L35.2739 18.3568L35.0395 18.2217L33.035 5.51173C33.0057 5.31988 32.9342 5.13701 32.8256 4.97644C32.7171 4.81586 32.5743 4.68162 32.4077 4.58347L24.6087 0.0633798C24.5376 0.0219489 24.457 8.69675e-05 24.3748 2.58935e-07C24.2926 -8.64496e-05 24.2119 0.0216051 24.1408 0.0628859C24.0696 0.104167 24.0105 0.163576 23.9695 0.235118C23.9285 0.306661 23.907 0.387805 23.9072 0.470361V24.7455C23.908 24.8664 23.9402 24.985 24.0006 25.0895C24.0611 25.194 24.1477 25.2808 24.2519 25.3412L29.3794 28.3183C29.4346 28.3501 29.4971 28.3668 29.5607 28.3667C29.6243 28.3667 29.6868 28.3499 29.7419 28.318C29.797 28.2861 29.8429 28.2402 29.8748 28.185C29.9068 28.1297 29.9238 28.067 29.9241 28.0031V16.4292L30.1843 16.5764L32.1733 29.5964C32.191 29.7134 32.2348 29.825 32.3013 29.9227C32.3678 30.0205 32.4555 30.1019 32.5576 30.1609L36.1219 32.227C36.1874 32.2648 36.2609 32.2863 36.3364 32.2898C36.4118 32.2933 36.487 32.2787 36.5557 32.2472C36.6244 32.2158 36.6847 32.1683 36.7316 32.1088C36.7784 32.0492 36.8105 31.9793 36.8251 31.9049L38.7365 21.5468L39.045 21.72V33.5328C39.046 33.6534 39.0784 33.7717 39.1388 33.8758C39.1993 33.98 39.2857 34.0665 39.3897 34.1268L45.2755 37.5247C45.3307 37.5565 45.3932 37.5732 45.4568 37.5732C45.5204 37.5731 45.5829 37.5563 45.638 37.5244C45.6931 37.4925 45.739 37.4466 45.7709 37.3914C45.8029 37.3361 45.8199 37.2734 45.8202 37.2095V13.1197C45.8194 12.8894 45.7582 12.6633 45.6426 12.4644C45.5269 12.2655 45.3611 12.1009 45.1618 11.9871L38.7709 8.27921C38.7097 8.24522 38.6416 8.22545 38.5718 8.22134C38.5019 8.21723 38.432 8.22889 38.3672 8.25546C38.3024 8.28204 38.2444 8.32285 38.1973 8.37491C38.1503 8.42696 38.1154 8.48894 38.0953 8.5563Z'
      fill={color}
    />
    <path
      d='M31.7364 31.9111L27.3759 29.3843C26.4124 28.8249 24.5389 28.3088 22.2294 28.5184C19.8165 28.7349 18.2101 29.6233 15.7041 31.0088C15.4266 31.1819 4.22197 37.6763 1.55739 39.2229C1.50014 39.2562 1.45261 39.304 1.41957 39.3616C1.38653 39.4192 1.36914 39.4846 1.36914 39.551C1.36914 39.6175 1.38653 39.6828 1.41957 39.7404C1.45261 39.798 1.50014 39.8459 1.55739 39.8792L22.4707 52.0021C22.6213 52.0896 22.7922 52.1358 22.9662 52.1358C23.1402 52.1358 23.3111 52.0896 23.4617 52.0021L29.2183 48.6596C29.2745 48.6269 29.3211 48.5799 29.3535 48.5234C29.386 48.4669 29.403 48.4028 29.403 48.3375C29.403 48.2723 29.386 48.2082 29.3535 48.1516C29.3211 48.0951 29.2745 48.0481 29.2183 48.0154C27.874 47.2378 24.6234 45.338 22.9705 44.3785L25.6403 42.8303C26.4212 42.3529 27.3552 42.1966 28.248 42.3939L36.8294 43.9664C37.059 44.0093 37.2962 43.9682 37.4981 43.8503L44.2854 39.9139C44.3322 39.8865 44.3694 39.8453 44.3921 39.796C44.4148 39.7466 44.4218 39.6914 44.4123 39.6379C44.4026 39.5844 44.3769 39.5351 44.3385 39.4968C44.3001 39.4585 44.2508 39.433 44.1975 39.4238L32.6188 37.4062C33.7684 35.0613 32.9549 32.6159 31.7364 31.9111ZM13.8117 39.0618L20.5834 35.134C21.6624 34.5001 22.1346 34.3858 22.7775 34.7651C23.2945 35.0717 23.7047 35.2985 24.2907 35.6484C24.9422 36.0363 24.6751 36.4744 23.7737 36.9594L16.964 40.8924L13.8117 39.0618Z'
      fill={color}
    />
    <path
      d='M21.9841 18.6372C21.9843 18.5903 21.9721 18.5442 21.9489 18.5035C21.9257 18.4628 21.8922 18.429 21.8518 18.4054C21.8115 18.3819 21.7656 18.3695 21.7189 18.3694C21.6722 18.3694 21.6264 18.3817 21.5859 18.4051L10.5192 24.832C10.5192 24.832 10.3813 24.3367 12.827 22.6533C15.4123 20.8713 18.1975 18.7827 20.3709 16.3858C22.415 14.1344 22.9562 10.6569 22.2667 7.87559C21.7497 5.76621 19.3212 4.00667 16.8738 3.59276C15.3089 3.32952 11.8756 3.46807 8.17516 5.59303C4.47475 7.71799 2.53405 10.1633 1.32241 13.0815C0.365057 15.4023 0.0777441 17.9472 0.493393 20.4245C0.504556 20.4862 0.529932 20.5444 0.567492 20.5944C0.605052 20.6445 0.653753 20.685 0.709699 20.7128C0.765646 20.7406 0.827284 20.7548 0.889683 20.7544C0.952082 20.7539 1.01351 20.7388 1.06905 20.7102L7.66328 17.3453C7.73569 17.3086 7.79537 17.2507 7.83444 17.1793C7.87351 17.1079 7.89015 17.0263 7.88216 16.9452C7.8248 16.253 7.90088 15.5562 8.10622 14.893C8.47161 13.6478 8.74393 13.1092 10.2331 11.9904C11.4792 11.0552 13.4733 10.6396 14.2454 11.315C15.3037 12.2398 14.5901 14.0496 12.4995 15.5632C9.60742 17.6605 7.85459 18.6095 4.45062 22.1442C1.61713 25.0883 0.314146 28.6715 0.0952579 30.864C-0.139142 33.1431 0.112493 35.6127 0.277952 36.8544C0.290247 36.944 0.324265 37.0292 0.377014 37.1026C0.429764 37.1759 0.499631 37.2351 0.580476 37.2749C0.661321 37.3148 0.750669 37.3341 0.840668 37.3311C0.930667 37.3281 1.01856 37.303 1.09663 37.2579L21.5722 25.3723C21.6958 25.3004 21.7984 25.1971 21.8698 25.0728C21.9412 24.9485 21.9788 24.8075 21.9789 24.664L21.9841 18.6372Z'
      fill={color}
    />
  </svg>
);
