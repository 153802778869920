import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetcher } from '../utils/fetcher';
import localStorage from '../utils/localStorage';

interface IUserData {
  name: string;
}

// @ts-ignore
const UserDataContext = createContext<IUserData>();

interface Props {
  children: React.ReactNode;
  name: string;
}

export const UserDataProvider = ({ children, name }: Props) => {
  return (
    <UserDataContext.Provider
      value={{
        name,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
