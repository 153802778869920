import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useAlert from '../../../contexts/alert';
import { useAuth } from '../../../contexts/AuthContext';

export const GoogleLoginHandler = () => {
  const alert = useAlert();
  const { authWithGoogle } = useAuth();
  const urlParams = useParams();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (urlParams?.result) {
      const token = params.get('token');
      const name = params.get('name');
      if (token) {
        authWithGoogle(token, name || null);
        navigate('/');
        return () => {};
      }
    }
    const reason = params.get('reason');
    alert.showError(reason || "Can't login using google");
    navigate('/');
  }, [urlParams?.view]);

  return null;
};
